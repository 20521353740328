<template>
  <div class="container-xxl flex-grow-1 container-p-y">


    <h4 class="fw-bold py-3 mb-4">
      <span class="text-muted fw-light">Ustawienia konta /</span> Konto
    </h4>

    <div class="row fv-plugins-icon-container">
      <div class="col-md-12">
        <ul class="nav nav-pills flex-column flex-md-row mb-3">
          <li class="nav-item"><router-link class="nav-link active" to="/user/me"><i class="bx bx-user me-1"></i>
              Konto</router-link></li>
          <li class="nav-item"><router-link class="nav-link" to="/user/me/secuity"><i class="bx bx-lock-alt me-1"></i>
              Bezpieczeństwo</router-link></li>
          <li class="nav-item"><router-link class="nav-link" to="/user/me/notifications"><i class="bx bx-bell me-1"></i>
              Powiadomienia</router-link></li>
        </ul>
        <div class="card mb-4">
          <h5 class="card-header">Twój profil</h5>
          <div v-if="success" class="alert alert-success" role="alert">
            <h6 class="alert-heading fw-bold mb-1">Profil został zaktualizowany</h6>
          </div>
          <div v-if="validation" class="alert alert-warning" role="alert">
            <h6 class="alert-heading fw-bold mb-1">Ensure that these requirements are met</h6>
            <span>{{ validation }}</span>
          </div>

          <!-- Account -->
          <div class="card-body">
            <div class="d-flex align-items-start align-items-sm-center gap-4">
              <img v-if="$store.state.user.avatar" :src="$store.state.user.avatar" alt="user-avatar"
                class="d-block rounded" height="100" width="100" id="uploadedAvatar">
              <div class="button-wrapper d-flex">
                <FileChooserInput folder="user" v-model="$store.state.user.avatar" hide_input="1"
                  title="Wybierz nowe zdjęcie" style="float:left">
                </FileChooserInput>
                <button v-if="$store.state.user.avatar" @click="$store.state.user.avatar = ''" type="button"
                  class="btn btn-label-secondary account-image-reset mb-4">
                  <i class="bx bx-reset d-block d-sm-none"></i>
                  <span class="d-none d-sm-block">Reset</span>
                </button>
              </div>
            </div>
          </div>
          <hr class="my-0">
          <div class="card-body">
            <form id="formAccountSettings" method="POST" onsubmit="return false"
              class="fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate">
              <div class="row">
                <div class="mb-3 col-md-6 fv-plugins-icon-container">
                  <label for="firstName" class="form-label">Imię nazwisko</label>
                  <input v-model="$store.state.user.name" class="form-control" type="text" id="firstName" name="firstName"
                    value="John" autofocus="">
                  <div class="fv-plugins-message-container invalid-feedback"></div>
                </div>

                <div class="mb-3 col-md-6">
                  <label for="email" class="form-label">E-mail</label>
                  <input v-model="$store.state.user.email" class="form-control" type="email" id="email" name="email"
                    placeholder="john.doe@example.com">
                </div>
                <!-- <div class="mb-3 col-md-6">
                  <label for="organization" class="form-label">Organization</label>
                  <input type="text" class="form-control" id="organization" name="organization" value="ThemeSelection">
                </div> -->
                <div class="mb-3 col-md-6">
                  <label class="form-label" for="phoneNumber">Numer telefonu</label>
                  <div class="input-group input-group-merge">
                    <span class="input-group-text">+48</span>
                    <input v-model="$store.state.user.phone_number" type="text" id="phoneNumber" name="phoneNumber"
                      class="form-control" placeholder="202 555 0111">
                  </div>
                </div>

              </div>
              <div class="mt-2">
                <button type="submit" @click.prevent="onSave" class="btn btn-primary me-2">Save changes</button>
                <button type="reset" class="btn btn-label-secondary">Cancel</button>
              </div>
              <input type="hidden">
            </form>
          </div>
          <!-- /Account -->
        </div>

      </div>
    </div>



  </div>
</template>
<script>
import FileChooserInput from '../filemanager/FileChooserInput.vue';
import axios from '../../api/axios';

export default {
  data: function () {
    return {
      validation: '',
      success: false,
    }
  },
  methods: {
    onSave() {
      this.validation = '';
      this.success = false;

      axios.put('api/v1/user/users/' + this.$store.state.user.id, this.$store.state.user).then(() => {
        this.success = true;
      }).catch(error => {
        if (error.response.data.validation) {
          this.validation = error.response.data.validation
        }
      });
    }
  },
  components: {
    FileChooserInput,
  }
}
</script>